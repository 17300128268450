var textTransform;

var $searchPoi, $searchFulltext, $autocomplete;

var LocX, LocY;

function setInputValue(name, value) {
	var input = document.querySelector('[name="' + name + '"]');
	if (input) {
		input.value = value;
	}
}

function getInputValue(name) {
	var input = document.querySelector('[name="' + name + '"]');
	if (input) {
		return input.value;
	}
	return '';
}

function getGroupLabel(Description) {
	switch (Description) {
		case 'G':
			return 'Amtliche Dienstleistungen';
		case 'B':
			return 'Nichtamtliche Dienstleistungen';
		case 'T':
			return 'Technischer Dienst';
		case 'Q':
			return 'Bau-Dienstleistungen';
		case 'K':
			return 'weitere Dienstleistungen';
		case 'A':
			return 'Anlagensicherheit';
		default:
			return 'Sonstige Dienstleistungen';
	}
}

function groupBy(array, key) {
	return array.reduce((acc, obj) => {
		const groupKey = obj[key].substring(2, 3);

		acc[groupKey] = acc[groupKey] || [];
		acc[groupKey].push(obj);

		return acc;
	}, {});
}

function sortBy(array, key) {
	return array.sort((a, b) => {
		const keyA = a[key];
		const keyB = b[key];

		if (keyA !== keyB) {
			return keyA - keyB;
		}

		const stringA = String(a[key]);
		const stringB = String(b[key]);

		return stringA.localeCompare(stringB);
	});
}

function orderBy(groupedData, order) {
	const orderMap = new Map(order.map((val, index) => [val, index]));

	const groupedArray = Object.entries(groupedData);

	const orderedGroups = groupedArray.sort(([keyA], [keyB]) => {
		const orderA = orderMap.get(keyA);
		const orderB = orderMap.get(keyB);

		if (orderA < orderB) {
			return -1;
		} else if (orderA > orderB) {
			return 1;
		} else {
			return 0;
		}
	});

	return Object.fromEntries(orderedGroups);
}

function groupAndSortBy(array, groupKey, sortKey) {
	const groupedData = groupBy(array, groupKey);

	for (const key in groupedData) {
		groupedData[key] = sortBy(groupedData[key], sortKey);
	}

	return groupedData;
}

var SearchView = ViewModel.extend({
	initialize: function (model, options) {
		options = options || {};
		if (!options.disableTypeahead) {
			this.enableTypeahead();
		}

		var self = this;

		if (navigator.geolocation) {
			$('#searchByPosition').click(function () {
				self.getPosition('searchForm');
			});
		} else {
			$('#searchByPosition').hide();
		}

		$('#searchForm').submit(function () {
			self.setShowOnlyResults();
		});

		$('#BranchCodes').change(function () {
			var select = $('#BranchCodes');
			var text = select.children("option[value='" + select.val() + "']").text();
			$('#BranchCodesDiv').html(text);
		});

		self.loadBranches();
	},

	setTextTransformer: function (transformer) {
		textTransform = transformer;
	},

	getTextTransformer: function () {
		return textTransform;
	},

	_createAutocomplete: function () {
		var searchConfig = module.config();

		$searchPoi = $(searchConfig.SingleSlotGeoInput);

		var fulltextValue = '';
		if ($searchPoi.hasClass('searchFulltext')) {
			fulltextValue = $searchPoi.val();
			$searchPoi.removeClass('searchFulltext');
			$searchPoi.val('');
		}

		var ac = ym.modules.autoComplete(searchConfig.SingleSlotGeoInput, { locales: ['de'] });
		var lastSelectedACInput = '';
		ac.on('selected', function (item) {
			var x = item.geometry.coordinates[0];
			var y = item.geometry.coordinates[1];

			setInputValue('LocX', x);
			setInputValue('LocY', y);

			lastSelectedACInput = ac.element.value;
			$(ac.element).closest('form').submit();
		});

		ac.element.addEventListener('input', function () {
			var searchInput = ac.element.value.trim();
			if (searchInput === '' || searchInput !== lastSelectedACInput) {
				setInputValue('LocX', '');
				setInputValue('LocY', '');
			}
		});

		$autocomplete = $searchPoi.closest('.sm-autocomplete');
		$searchFulltext = $searchPoi.clone();
		$searchFulltext.addClass('searchFulltext');
		$searchFulltext.val(fulltextValue);
	},

	disableTypeahead: function () {
		if (!$autocomplete) {
			this._createAutocomplete();
		}
		$autocomplete.detach();
		$searchFulltext.appendTo('.search-input-container');
		LocX = getInputValue('LocX');
		LocY = getInputValue('LocY');
		setInputValue('LocX', '');
		setInputValue('LocY', '');
	},

	enableTypeahead: function () {
		if (!$autocomplete) {
			this._createAutocomplete();
		}
		$autocomplete.appendTo('.search-input-container');
		if ($searchFulltext) {
			$searchFulltext.detach();
		}
		if (LocX && LocY) {
			setInputValue('LocX', LocX);
			setInputValue('LocY', LocY);
			LocX = undefined;
			LocY = undefined;
		}
	},

	render: function () {},

	getPosition: function (LocFormMobile) {
		var self = this;
		var errorText =
			'Fehler: Sie haben die automatische Lokalisierung abgelehnt, Ihr Standort konnte nicht ermittelt werden oder Ihr Endgerät unterstützt die automatische Lokalisierung nicht.';
		if (navigator.geolocation) {
			try {
				navigator.geolocation.getCurrentPosition(
					function (p) {
						document.forms[LocFormMobile].action +=
							'&LocX=' + escape(p.coords.longitude) + '&LocY=' + escape(p.coords.latitude);
						self.setShowOnlyResults();
						document.forms[LocFormMobile].submit();
					},
					function (error) {
						alert(errorText);
					},
					{
						timeout: 5000
					}
				);
			} catch (e) {
				alert(errorText);
			}
		} else {
			// no native geolocation support available :(
			alert(errorText);
		}
	},

	setShowOnlyResults: function () {
		//wenn die Karte aktuell nicht zu sehen ist, wollen wir sehr wahrscheinlich als nächstes nur die Ergebnisse ohne Karte
		if ($('.searchDivKurz').css('display') !== 'none') {
			var newPage = $('#ShowOnlyResults');
			newPage.val(true);
		}
	},

	branchSearchSuccess: function (result) {
		var select = $('#BranchCodes');
		var mode = $('#Mode').val();
		var lastBC = config.BranchCodes;
		var index = lastBC.indexOf('&');
		if (index > 0) {
			lastBC = lastBC.substr(0, index);
		}
		result.BranchListElements = _.filter(result.BranchListElements, function (value) {
			var branchCode = value.BranchCode.substring(0, 3);
			switch (mode) {
				case 'Anlagensicherheit':
					return branchCode === 'GTA';
				case 'Bau':
					return branchCode === 'GTQ' || branchCode === 'GTK';
				default:
					return branchCode === 'GTB' || branchCode === 'GTG' || branchCode === 'GTT';
			}
		});

		const groupedBranchListElements = groupAndSortBy(result.BranchListElements, 'BranchCode', 'SortNr');

		const groupOrder = ['G', 'T', 'B', 'Q', 'K'];
		const orderedBranchListElements = orderBy(groupedBranchListElements, groupOrder);

		for (let group in orderedBranchListElements) {
			let label = getGroupLabel(group);
			let optgroup = $('<optgroup label="' + label + '"></optgroup>');

			groupedBranchListElements[group].forEach((value) => {
				if (lastBC === value.BranchCode) {
					optgroup.append(
						'<option value="' + value.BranchCode + '" selected="selected">' + value.BranchText + '</option>'
					);
					$('#BranchCodesDiv').html(value.BranchText);
				} else {
					optgroup.append('<option value="' + value.BranchCode + '">' + value.BranchText + '</option>');
				}
			});

			select.append(optgroup);
		}
	},

	loadBranches: function () {
		$.ajax({
			url: '/api_js/V2/BranchSearches.asmx/SearchPartnerBranchCodes',
			dataType: 'jsonp',
			data: {
				SystemPartner: ym.options.name,
				SecurityID: ym.options.securityID,
				Channel: ym.options.channel,
				IsoCountryCode: ym.options.isoCountryCode || 'DE',
				IsoLocale: ym.options.isoLocale || 'de-DE',
				Addition: ''
			},
			success: this.branchSearchSuccess
		});
	}
});
